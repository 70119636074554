exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-feature-panel-tsx": () => import("./../../../src/pages/featurePanel.tsx" /* webpackChunkName: "component---src-pages-feature-panel-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-work-tsx": () => import("./../../../src/pages/work.tsx" /* webpackChunkName: "component---src-pages-work-tsx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-authentic-self-index-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/content/blog/authenticSelf/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-authentic-self-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-burnout-01-index-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/content/blog/burnout01/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-burnout-01-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-great-flip-flop-index-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/content/blog/great-flip-flop/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-great-flip-flop-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-hello-deary-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/content/blog/hello-deary/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-hello-deary-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-setting-boundaries-index-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/content/blog/setting-boundaries/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-setting-boundaries-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-the-person-next-to-you-index-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/content/blog/the-person-next-to-you/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-the-person-next-to-you-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-what-on-mind-index-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/content/blog/what-on-mind/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-what-on-mind-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-where-to-start-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/content/blog/where-to-start/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-where-to-start-index-mdx" */),
  "component---src-templates-work-post-tsx-content-file-path-content-work-nand-to-tetris-assembler-index-mdx": () => import("./../../../src/templates/work-post.tsx?__contentFilePath=/opt/build/repo/content/work/nandToTetrisAssembler/index.mdx" /* webpackChunkName: "component---src-templates-work-post-tsx-content-file-path-content-work-nand-to-tetris-assembler-index-mdx" */),
  "component---src-templates-work-post-tsx-content-file-path-content-work-nand-to-tetris-compiler-index-mdx": () => import("./../../../src/templates/work-post.tsx?__contentFilePath=/opt/build/repo/content/work/nandToTetrisCompiler/index.mdx" /* webpackChunkName: "component---src-templates-work-post-tsx-content-file-path-content-work-nand-to-tetris-compiler-index-mdx" */),
  "component---src-templates-work-post-tsx-content-file-path-content-work-nand-to-tetris-syntax-analyzer-index-mdx": () => import("./../../../src/templates/work-post.tsx?__contentFilePath=/opt/build/repo/content/work/nandToTetrisSyntaxAnalyzer/index.mdx" /* webpackChunkName: "component---src-templates-work-post-tsx-content-file-path-content-work-nand-to-tetris-syntax-analyzer-index-mdx" */),
  "component---src-templates-work-post-tsx-content-file-path-content-work-nand-to-tetris-vm-translator-index-mdx": () => import("./../../../src/templates/work-post.tsx?__contentFilePath=/opt/build/repo/content/work/nandToTetrisVMTranslator/index.mdx" /* webpackChunkName: "component---src-templates-work-post-tsx-content-file-path-content-work-nand-to-tetris-vm-translator-index-mdx" */),
  "component---src-templates-work-post-tsx-content-file-path-content-work-tic-tac-toe-index-mdx": () => import("./../../../src/templates/work-post.tsx?__contentFilePath=/opt/build/repo/content/work/ticTacToe/index.mdx" /* webpackChunkName: "component---src-templates-work-post-tsx-content-file-path-content-work-tic-tac-toe-index-mdx" */),
  "component---src-templates-work-post-tsx-content-file-path-content-work-type-ramp-index-mdx": () => import("./../../../src/templates/work-post.tsx?__contentFilePath=/opt/build/repo/content/work/typeRamp/index.mdx" /* webpackChunkName: "component---src-templates-work-post-tsx-content-file-path-content-work-type-ramp-index-mdx" */)
}

